*{margin: 0; padding: 0;}
*:not(input) {
  
}
a, img {
    -webkit-touch-callout: none; /*禁止长按链接与图片弹出菜单*/
    text-decoration:none;
    cursor:pointer;
}
@font-face {
    font-family: 'webfontx';
    font-display: swap;
    src: url('//at.alicdn.com/t/webfont_v1n0eg43nkb.eot'); /* IE9*/
    src: url('//at.alicdn.com/t/webfont_v1n0eg43nkb.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('//at.alicdn.com/t/webfont_v1n0eg43nkb.woff2') format('woff2'),
    url('//at.alicdn.com/t/webfont_v1n0eg43nkb.woff') format('woff'), /* chrome、firefox */
    url('//at.alicdn.com/t/webfont_v1n0eg43nkb.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url('//at.alicdn.com/t/webfont_v1n0eg43nkb.svg#思源黑体-细') format('svg'); /* iOS 4.1- */
  }

button,input,optgroup,select,textarea {
    /*-webkit-appearance:none; !*去掉webkit默认的表单样式*!*/
}

a,button,input,optgroup,select,textarea {
    -webkit-tap-highlight-color:rgba(0,0,0,0); /*去掉a、input和button点击时的蓝色外边框和灰色半透明背景*/
}

input::-webkit-input-placeholder {
    /*修改webkit中input的planceholder样式*/
}

input:focus::-webkit-input-placeholder {
     /*修改webkit中focus状态下input的planceholder样式*/
}

html,body {
    -webkit-text-size-adjust: 100%!important; /*禁止IOS调整字体大小*/
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width:100%; 
    min-height:100%;
    font-size:14px;
    -webkit-overflow-scrolling: touch;
    // overflow-x: hidden;
}
html{
    // overflow: hidden;
}
body{
    // overflow-y: auto;
    // overflow-x: hidden;
}

input::-webkit-input-speech-button {
    display: none; /*隐藏Android的语音输入按钮*/
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td, button{padding:0;margin:0;}
fieldset, img{border:0;}
input, label, select, option, textarea, button, fieldset, legend{resize: none;outline:none;}
ul{list-style:none;}

ul,li{list-style:none;}
i{font-style: inherit;}
h1,h2{ font-weight:normal;}
img{vertical-align:top;border:0;-webkit-touch-callout: none;max-width: 100%;}

table { 
 border-collapse:collapse; 
 border-spacing:0; 
} 
th,td { 
 padding: 0; 
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset; 
}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active { -webkit-transition-delay: 9999s; -webkit-transition: color 9999s ease-out, background-color 9999s ease-out; }
.i-icon{
    svg{
        vertical-align: middle;
        text-anchor: middle;  /* 文本水平居中 */
        dominant-baseline: middle; /* 文本垂直居中 */
        transform: translateY(-2px);
      }
}
.markdown-body strong {
    font-weight: bolder
}

.markdown-body .hljs-center {
    text-align: center
}

.markdown-body .hljs-right {
    text-align: right
}

.markdown-body .hljs-left {
    text-align: left
}

#__next{
    background-color: #f8f8f8;
    min-height: 100%;
}

.load{
    text-align: center;
    position: absolute;
    width: 250px;
    /* height: 80px; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    .logo{
        height: 70px;
    }
}

.userAgreement{
    line-height: 34px;
    border-radius: 10px;
    color: #5a5a5a;
    padding: 34px 40px;
    background: #fff;
    h4{
        line-height: 40px;
    }
}

.color-choice{
    .ant-popover-inner-content{
        padding: 0;
    }
}
// .ant-btn-primary{
//     color: #444 !important;
// }